import React from 'react';
import Page from '../components/layouts/Page';
import Sidecard from '../components/molecules/Sidecard';
import FancyLink from '../components/molecules/FancyLink';
import ImageSlider from '../components/molecules/ImageSlider';
import CallToAction from '../components/CallToAction';
import WoodSpacer from '../components/molecules/WoodSpacer';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function contentSidebarCtaSlider({ data }) {
  const content = data.wpPage;
  const innerContent = data.wpPage.paginaTemplate.contentSidebarCtaSlider;

  const allJsonBreadcrumbs = [];
  const allBreadcrumbs = [];

  if (content.seo.breadcrumbs !== null) {
    content.seo.breadcrumbs.forEach((breadcrumb, index) => {
      allJsonBreadcrumbs.push({
        position: index + 1,
        name: breadcrumb.name,
        item: 'https://graafdaron.nl' + breadcrumb.item,
      });
      allBreadcrumbs.push({
        name: breadcrumb.name,
        item: breadcrumb.item,
      });
    });
  }

  return (
    <>
      {content.seo.breadcrumbs ? (
        <>
          <GatsbySeo
            title={content.seo.seoData.title}
            description={content.seo.seoData.description}
            openGraph={{
              type: 'website',
              url: 'https://graafdaron.nl/' + content.slug,
              title: content.seo.seoData.title,
              description: content.seo.seoData.description,
            }}
          />
          <BreadcrumbJsonLd itemListElements={allJsonBreadcrumbs} />
        </>
      ) : (
        ''
      )}
      <Page
        breadcrumbs={
          <>
            <>
              {allBreadcrumbs.map((breadcrumb) => {
                return (
                  <Breadcrumb title={breadcrumb.name} link={breadcrumb.item} />
                );
              })}
            </>
          </>
        }
        sidebar={true}
        title={content.title}
        html={innerContent.text}
        aside={
          innerContent.sidebar &&
          innerContent.sidebar.map((bar) => {
            if (bar.contact) {
              return (
                <Sidecard title="Contactgegevens">
                  {data.wp?.aparteInstellingen.globalSettings.contactData.map(
                    (contact) => {
                      return (
                        <div className="mb-4">
                          <div className="flex items-center flex-nowrap">
                            <img
                              className="mr-3"
                              src={contact.icon.localFile.url}
                              alt="iconContact"
                            />
                            <a
                              href={contact.link.url}
                              className="underline font-body"
                            >
                              {contact.link.title}
                            </a>
                          </div>
                          {contact.subtext && (
                            <span className="block ml-8 text-sm font-body">
                              *{contact.subtext}
                            </span>
                          )}
                        </div>
                      );
                    },
                  )}
                </Sidecard>
              );
            } else if (bar.socialmedia) {
              return (
                <Sidecard title="Deel dit artikel">
                  <FancyLink
                    link={`https://www.facebook.com/sharer/sharer.php?u=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Facebook"
                  />
                  <FancyLink
                    link={`https://twitter.com/intent/tweet?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Twitter"
                  />
                  <FancyLink
                    link={`https://api.whatsapp.com/send?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="WhatsApp"
                  />
                </Sidecard>
              );
            } else {
              return (
                <Sidecard title={bar.sidebarItem.title}>
                  {bar.linksOrText
                    ? bar.sidebarItem.links.map((link) => {
                        return (
                          <FancyLink
                            link={link.link.url}
                            target={link.link.target}
                            title={link.link.title}
                          />
                        );
                      })
                    : bar.sidebarItem.text}
                </Sidecard>
              );
            }
          })
        }
        bottom={
          <>
            {innerContent.callToAction.type === 'customhtml' && (
              <>
                <CallToAction
                  style={innerContent.callToAction.type}
                  html={innerContent.callToAction.customHtml}
                />
                <WoodSpacer small={true} />
              </>
            )}
            {innerContent.callToAction.type !== 'customhtml' && (
              <>
                <ImageSlider />
                <CallToAction
                  style={innerContent.callToAction?.type ?? 'bigtower'}
                  html={innerContent.callToAction?.customHtml}
                  title={innerContent.callToAction?.title}
                  text={innerContent.callToAction?.text}
                  buttontext={innerContent.callToAction.button?.title}
                  buttonurl={innerContent.callToAction.button?.url}
                />
                <WoodSpacer small={true} />
              </>
            )}
          </>
        }
      />
    </>
  );
}

export const query = graphql`
  query contentSidebarCtaSliderQuery($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      paginaTemplate {
        contentSidebarCtaSlider {
          text
          sidebar {
            linksOrText
            sidebarItem {
              title
              text
              links {
                link {
                  title
                  url
                  target
                }
              }
            }
            contact
            socialmedia
          }
          callToAction {
            type
            text
            customHtml
            title
            button {
              target
              title
              url
            }
          }
        }
      }
      seo {
        seoData {
          description
          title
        }
        breadcrumbs {
          item
          name
        }
      }
    }
    wp {
      aparteInstellingen {
        globalSettings {
          contactData {
            subtext
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 24)
                }
              }
            }
            link {
              title
              url
            }
          }
        }
      }
    }
  }
`;
